.header {
  padding: 10px;
  color: #007EB9;
  background-color: #eee;
}

.header h1 {
  margin: 0;
  font-size: 24px;
}
