.registration-form__header {
  margin-bottom: 25px;
}

.registration-form {
  width: 700px;
}

.registration-form__errors {
  padding: 10px;
  margin-bottom: 10px;
  border-left: solid 5px red;
  color: red;
  display: flex;
}

.registration-form__errors__icon {
  font-size: 32px;
  margin-right: 15px;
}

.registration-form__errors__text {
  text-align: left;
  font-size: 0.9em;
}

.registration-form__field {
  text-align: left;
  padding-left: 10px;
  border-left: 5px solid transparent;
}

.registration-form__field_error {
  border-color: red;
}

.registration-form__field__fields {
  display: flex;
  gap: 24px;
  margin: 10px 0;
}

.registration-form__field__fields > div {
  flex: 1;
  display: flex;
}

.registration-form__field input {
  font-size: 1.2em;
  width: 100%;
  border: solid 3px transparent;
}

.registration-form__field__label {
  padding: 5px 0;
}

.registration-form__field__label__error {
  font-size: 0.8em;
  color: red;
}

.registration-form__field input.error {
  border-color: red;
}

.loading__container {
  margin-top: 50px;
}

.loading__spinner {
  font-size: 96px;
  animation: rotation 2s infinite linear;
}

.loading__text {
  margin-top: 64px;
}

.registration-form__buttons {
  margin-top: 20px;
  text-align: right;
}

@media all and (max-width: 700px) {
  .registration-form {
    width: auto;
  }

  .registration-form__field {
    margin: 0 15px 0 10px;
    border-color: #007EB9;
  }
    
  .registration-form__field_error {
    border-color: red;
  }

  .registration-form__field__fields {
    display: block;
  }
  
  .registration-form__field__fields > div {
    margin-bottom: 10px;
  }

  .registration-form__buttons {
    margin-right: 15px;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
