.App {
  text-align: center;
  background-color: #282c34;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 50px;
  width: 800px;
  margin: 0 auto;
  background-color: #202933;
}

.App-link {
  color: #61dafb;
}

.button {
  font-size: 1em;
  background-color: #007EB9;
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
  user-select: none;
  text-align: left;
  border: 0;
  color: #fff;
  white-space: nowrap;
}

.button__icon, .button__text {
  display: inline-block;
}

.button__icon {
  margin-right: 10px;
  width: 25px;
  text-align: center;
}

@media all and (max-width: 700px) {
  .App-header {
    width: auto;
    padding-top: 20px;
  }
}
